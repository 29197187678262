import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import Perk from "./Perk"

const PerksModule = () => {
  return (
    <PerksModuleStyles>
      <StaticImage
        className="perks__image--bg"
        src="../../images/perksBackgroundv4.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="perks__image--overlay"></div>
      <div className="container">
        <Perk
          title="Ръчно изработени"
          content="Всички наши продукти са ръчна изработка"
        >
          <StaticImage
            src="../../images/logos/handmade.svg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
            
          />
        </Perk>
        <Perk
          title="Висококачествени"
          content="Полагаме усилия за това всеки от продуктите ни да е с максимално добро качество"
        >
          <StaticImage
            src="../../images/logos/quality_v3.svg"
            alt="Perk Image"
            layout="fullWidth"
            placeholder="tracedSVG"
            
          />
        </Perk>
        <Perk
          title="По наш дизайн"
          content="Продуктите ни са с оригинален дизайн и възможност за персонализиране"
        >
          <StaticImage
            src="../../images/logos/original.svg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
            
          />
        </Perk>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule

import * as React from "react"
import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
import { Link } from "gatsby"

const BasicTextModule = () => {
  return (
    <BasicTextModuleStyles>
      <div className="container">
        <h2>
          Имаме удоволствието да Ви представим нашите ръчно изработени продукти.
        </h2>
        <p style={{ marginBottom: "60px" }}>
          С внимание към детайла, с добре подбрани материали, с оригиналните си дизайни, 
          можем да Ви предложим наистина уникални продукти, с които да зарадвате себе си,
          или любимите си хора.
        </p>
        <Button text="Разгледай продуктите" as={Link} to="/products" />
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule
